import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import { Link } from "gatsby"
import { withPrefix } from 'gatsby'
import useSiteMetadata from '../components/use-site-metadata';
import Icon from '../components/icons';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import DOD from '../components/dods';
import ReactGA from 'react-ga';
import Form from "../components/stay-connected-form";
import Modal from 'react-modal';
import closeIcon from '../_images/icons/white/close.svg';
import Vimeo from '@u-wave/react-vimeo';
import * as queryString from "query-string";


import resilience from '../_images/Titan-of-Resilience.svg';
import tassel from '../_images/Titan-of-Tassel.svg';
import empowerment from '../_images/Titan-of-Empowerment.svg';
import empowerment2 from '../_images/Titan-of-Empowerment-2.svg';
import empowerment3 from '../_images/Titan-of-Empowerment-2-blue-white.svg';
import empowerment4 from '../_images/Titan-of-Empowerment-2-white.svg';
import justice from '../_images/titan-of-justice.svg';
import itatlogo from '../_images/campaign-logo-white.svg'
import innovation from '../_images/Titans-of-Innovation.svg';

Modal.setAppElement('#___gatsby')

export class VideoModal extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Watch Video'
    });
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    return (
      <React.Fragment>
        <button id="playVideo" className="video-button" onClick={this.openModal}>
          <div className="video-button-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><title>Watch Video</title><path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"/></svg></div>
          <span className="video-button-text">Watch Video</span>
        </button>
        <Modal
          closeTimeoutMS={400}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Watch Video"
          className="videoModal"
          overlayClassName="videoOverlay"
          shouldCloseOnOverlayClick={true}
        >
          <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
          <div className="wrap-video">
            <Vimeo
              video={this.props.vimeoID}
              responsive
              showTitle="false"
              showByline="false"
              showPortrait="false"
              autoplay
              onEnd={this.closeModal}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export class FormModal extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    return (
      <React.Fragment>
      <button className="buttonBlue outline" onClick={this.openModal}><Icon name="email" /> Subscribe</button>
      <Modal
        closeTimeoutMS={400}
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
        contentLabel="Receive Updates"
        className="modal"
        overlayClassName="overlay"
      >
        <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
        <div className="wrap">
          <Form profile={this.props.paths} college="Cal State Fullerton" />
        </div>
      </Modal>
      </React.Fragment>
    );
  }
}

export default function Template({
  data,
  location
}) {

  const { siteUrl } = useSiteMetadata();
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  
  var pageClass = frontmatter.path;
  pageClass = pageClass.split("/").pop();
  pageClass = pageClass + ' ' + frontmatter.theme;


  function GraphicTitle() {
    if (frontmatter.theme == 'resilience') {
      return <h1><img src={resilience} alt="Titan of Resilience" /></h1>
    } else if (frontmatter.theme == 'tassel') {
      return <h1><img src={tassel} alt="Titan of Tassel" /></h1>
    } else if (frontmatter.theme == 'empowerment') {

      if (frontmatter.adkey == 'frida-mendez-arce') {
        return <h1><img src={empowerment} alt="Titan of Empowerment" /></h1>
      } else if (frontmatter.adkey == 'james-anthony-burandt') {
        return <h1><img src={empowerment3} alt="Titan of Empowerment" /></h1>
      } else if (frontmatter.adkey == 'makar-makarian-empowerment'  || frontmatter.adkey == 'alexis-ruiz') {
        return <h1><img src={empowerment4} alt="Titan of Empowerment" /></h1>
      } else {
        return <h1><img src={empowerment2} alt="Titan of Empowerment" /></h1>
      }

    } else if (frontmatter.theme == 'justice') {
      return <h1><img src={justice} alt="Titan of Justice" /></h1>
    } else if (frontmatter.theme == 'innovation') {
      return <h1><img src={innovation} alt="Titans of Innovation" /></h1>
    } else {
      return null;
    }
  }

  function HeroVideo() {
    if (frontmatter.videoPoster && frontmatter.videoWEBM && frontmatter.videoMP4 && frontmatter.videoDesc) {
      return (
        <React.Fragment>
        <VideoModal vimeoID={frontmatter.vimeoID} />
        <video id="heroVideo" autoPlay loop muted="muted" poster={ withPrefix(frontmatter.videoPoster) } title="Intro Video">
          <source src={ withPrefix(frontmatter.videoWEBM) } type="video/webm; codecs=vp9,vorbis" />
          <source src={ withPrefix(frontmatter.videoMP4) } type="video/mp4" />
          <track kind="descriptions"  src={ withPrefix(frontmatter.videoDesc) } />
        </video>
        </React.Fragment>
      )
    } else {
      return null;
    }
  }

  var intro = (html.split('</p>')
    .splice(0,frontmatter.introGraph)
    .join('</p>') +
    '</p>'
   ).replace(/\<\/p> *\<\/p>/g,'</p>');

  // var end = html.split('</p>').slice(frontmatter.introGraph);

  var end = (html.split('</p>')
    .slice(frontmatter.introGraph)
    .join('</p>') +
    '</p>'
   ).replace(/\<\/p> *\<\/p>/g,'</p>');

  var heroImage = {
    backgroundImage: `url(${frontmatter.heroImage.publicURL})`
  };

  var contentStyles = {
    backgroundImage: `url(${frontmatter.Image3.publicURL})`
  };

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title}</title>
        <body className={"digital-ad-landing-page " + pageClass} />

        <link rel="canonical" href={`${siteUrl}${frontmatter.path || "/"}`} />
        <meta name="description" content={frontmatter.desc} />

        {/* Schema.org markup for Google+ */}
        <meta itemprop="name" content={frontmatter.title} />
        <meta itemprop="description" content={frontmatter.desc} />
        <meta itemprop="image" content={frontmatter.socialImage.publicURL} />

        {/* Twitter Card data */}
        <meta name="twitter:title" content={frontmatter.title} />
        <meta name="twitter:description" content={frontmatter.desc} />
        <meta name="twitter:image:src" content={frontmatter.socialImage.publicURL} />

        {/* Open Graph data */}
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:url" content={`${siteUrl}${frontmatter.path || "/"}`} />
        <meta property="og:image" content={frontmatter.socialImage.publicURL} />
        <meta property="og:image:alt" content={frontmatter.title} />
        <meta property="og:description" content={frontmatter.desc} />
      </Helmet>
      <section className="hero">
        <div class="hero-text">
          <GraphicTitle />
          <p className="quote">{frontmatter.quoteOrSubHead}</p>
          <div dangerouslySetInnerHTML={{ __html: intro }} />
          <AnchorLink href='#content' className="continue-reading-button">Continue Reading <Icon name="arrow-down" class="lg animated infinite bounce slow" /> </AnchorLink>
        </div>
        <div class="hero-media" style={heroImage}>
          <HeroVideo />
        </div>
      </section>
      {/* <button className="mobile-cta-action"><Icon name="arrow-up" class="lg" alt="Open Actions" /> Stay Connected</button> */}
      
      <section className="content" id="content">
        <div className="content-item content-text" dangerouslySetInnerHTML={{ __html: end }} />
        <div className="content-item content-image" style={contentStyles} />
      </section>

      <CTAStuff frontmatter={frontmatter} location={location}  />

    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        heroImage {
          publicURL
        }
        Image1 {
          publicURL
        }
        Image2 {
          publicURL
        }
        Image3 {
          publicURL
        }
        socialImage {
          publicURL
        }
        path
        adkey
        title
        desc
        theme
        college
        introGraph
        quoteOrSubHead
        videoPoster
        videoWEBM
        videoMP4
        videoDesc
        vimeoID
        fundDesc
        givingLink
        donateButtonText
      }
    }
  }
`

export class CTAStuff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.ctaNavigate = this.ctaNavigate.bind(this);
    this.finalCTA = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('load', this.handleScroll);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.handleScroll);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {

    var rect = this.finalCTA.current.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    // return !(rect.bottom < 0 || rect.top - viewHeight >= 0);

    if (rect.bottom < 0 || rect.top - viewHeight >= 0) {
      this.setState({scroll: false});
    } else {
      this.setState({scroll: true});
    }
    // console.log(this.state.scroll)

  }

  ctaNavigate(link) {
    window.location.href=link;
  }

  render() {

    var arr = this.props.frontmatter.givingLink.split('?');
    // console.log(arr);
    if (arr.length > 1 && arr[1] !== '') {
      // console.log('Giving link has query strings');
      var paramCharacter = '&';
    } else {
      // console.log('Giving link has no query strings');
      var paramCharacter = '?';
    }

    // query-string parses the parameters that are contained in the location object
    const querystrings = queryString.parse(this.props.location.search);
    if(querystrings.source && querystrings.source == 'digitalad') {
      // console.log('This came from a digitalad');
      // const adkey = this.props.frontmatter.path.substring(this.props.frontmatter.path.lastIndexOf('/') + 1);
      const adkey = this.props.frontmatter.adkey;
      // console.log(adkey);

      var givingQueryString = paramCharacter + 'digitalad=' + adkey;

      // console.log(givingQueryString);
    } else if(querystrings.source && querystrings.source == 'socialad') {
        // console.log('This came from a socialad');
        // const adkey = this.props.frontmatter.path.substring(this.props.frontmatter.path.lastIndexOf('/') + 1);
        const adkey = this.props.frontmatter.adkey;
        // console.log(adkey);

        var givingQueryString = paramCharacter + 'digitalad=' + adkey + '-social';

        // console.log(givingQueryString);
    } else {
      var givingQueryString = '';
    }

    var newGivingLink = this.props.frontmatter.givingLink + givingQueryString;
    // console.log(newGivingLink);

    var readmore = '';
    if (this.props.frontmatter.theme == 'resilience') {
      readmore = <Link to="/resilience">Read more stories of Resilience</Link>
    } else if (this.props.frontmatter.theme == 'tassel') {
      readmore = <Link to="/tassel">Read more stories of Tassel</Link>
    } else if (this.props.frontmatter.theme == 'empowerment') {
      readmore = <Link to="/empowerment">Read more stories of Empowerment</Link>
    } else if (this.props.frontmatter.theme == 'justice') {
      // readmore = <h1><img src={justice} alt="Titan of Justice" /></h1>
    }

    var donateNowText = 'Donate Now';
    console.log(this.props.frontmatter.donateButtonText);
    if (this.props.frontmatter.donateButtonText) {
      donateNowText = this.props.frontmatter.donateButtonText;
    }

    return (
      <React.Fragment>
      <section className={this.state.scroll ? 'cta animated fadeInUp static' : 'cta animated fadeInUp'}>
        <div className="cta-item give">
          <div className="cta-text">
            <h3>{this.props.frontmatter.fundDesc}</h3>
          </div>
          {/* <a href={newGivingLink} className="button orange">Donate Now</a> */}
          <button className="button orange" onClick={() => this.ctaNavigate(newGivingLink)}>{donateNowText}</button>
        </div>
        <div className="cta-item apply">
          <div className="cta-text">
            <h3>Become a Titan.</h3>
            <p>Learn about CSUF's <a href="http://www.fullerton.edu/future-students/costs/">affordable education</a>.</p>
          </div>
          {/* <a href="http://admissions.fullerton.edu/" className="buttonBlue outline">Apply</a> */}
        </div>
        <div className="cta-item stay-connected">
          <h3>Stay Connected</h3>
          <ul className="social-links">
            <li><FormModal path={this.props.frontmatter.path} /></li>
            <li><a className="facebook" href="http://www.facebook.com/csufofficial"><Icon name="facebook" class="sm" alt="Facebook" /></a></li>
            <li><a className="twitter" href="http://twitter.com/csuf"><Icon name="twitter" class="sm" alt="Twitter" /></a></li>
            <li><a className="instagram" href="http://instagram.com/csufofficial"><Icon name="instagram" class="sm" alt="Instagram" /></a></li>
            <li><a className="linkedin" href="http://www.linkedin.com/edu/california-state-university-fullerton-17827"><Icon name="linkedin" class="sm" alt="Linkedin" /></a></li>
          </ul>
        </div>
      </section>
      
      <section className="final-cta" ref={this.finalCTA}>
        <div className="final-cta-item dod-contact-text">
          <DOD  college={this.props.frontmatter.college} layout="2" />
        </div>
        <div className="final-cta-item more-stories-text">
          <img src={itatlogo} alt="It Takes a Titan" />
          {readmore}
        </div>
      </section>
      </React.Fragment>
    );
  }
}